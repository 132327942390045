import './bootstrap'

import * as Sentry from "@sentry/browser";
import {BrowserTracing} from "@sentry/tracing";
import {Dialog, Notification} from './utils/sweetalerts'

import { __ } from './translation.js'

if (window.SentryConfig.APP_ENV !== 'local' && window.SentryConfig.DSN) {
    Sentry.init({
        dsn: window.SentryConfig.DSN,
        integrations: [
            new BrowserTracing(),
            Sentry.feedbackIntegration({
                colorScheme: "light",
                maskAllText: false,
                blockAllMedia: false,
                showBranding: false,
                buttonLabel: __("Report a bug"),
                submitButtonLabel: __("Send a bug report"),
                cancelButtonLabel: __("Cancel"),
                formTitle: __("Report a bug"),
                namePlaceholder: __("Your Name"),
                successMessageText: __("Thank you for your feedback!"),
                messageLabel: __("Describe the problem"),
                messagePlaceholder: __("What is the error? What did you expect?"),
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: window.SentryConfig.SENTRY_TRACES_SAMPLE_RATE,
    });
}

window.Dialog = Dialog
window.Notification = Notification

window.addEventListener('showNotification', (event) => {
    switch (event.detail.type) {
        case 'success':
            Notification.success(
                event.detail.title ?? null,
                event.detail.text ?? null,
                event.detail.config ?? null
            )
            break
        case 'error':
            Notification.error(
                event.detail.title ?? null,
                event.detail.text ?? null,
                event.detail.config ?? null
            )
            break
        case 'warning':
            Notification.warning(
                event.detail.title ?? null,
                event.detail.text ?? null,
                event.detail.config ?? null
            )
            break
        case 'info':
            Notification.info(
                event.detail.title ?? null,
                event.detail.text ?? null,
                event.detail.config ?? null
            )
            break
        case 'question':
            Notification.question(
                event.detail.title ?? null,
                event.detail.text ?? null,
                event.detail.config ?? null
            )
            break
        default:
            Notification.info(
                event.detail.title ?? null,
                event.detail.text ?? null,
                event.detail.config ?? null
            )
            break
    }
})

window.addEventListener('showLivewireDeleteDialog', (event) => {
    let title = event.detail.title
    let text = event.detail.text
    let livewireId = event.detail.livewireId
    let modelId = event.detail.modelId ?? null
    let modelClassName = event.detail.modelClassName ?? null

    Dialog.confirm(title, text).then((result) => {
        if (result.isConfirmed) {
            let component = Livewire.find(livewireId)
            component.dispatchSelf(
                'livewireDeleteDialogConfirmed',
                [
                    modelId,
                    modelClassName
                ]
            )
        }
    })
})

window.addEventListener('showLivewireCloseDialog', (event) => {
    let title = event.detail.title
    let text = event.detail.text
    let livewireId = event.detail.livewireId
    let modelId = event.detail.modelId ?? null
    let modelClassName = event.detail.modelClassName ?? null

    Dialog.confirm(title, text).then((result) => {
        if (result.isConfirmed) {
            let component = Livewire.find(livewireId)
            component.dispatch(
                'livewireCloseDialogConfirmed',
                [
                    modelId,
                    modelClassName
                ]
            )
        }
    })
})

window.addEventListener('load', (event) => {
    const notificationTypes = [
        {
            querySelector: '.success',
            notificationFunction: Notification.success,
        },
        {
            querySelector: '.error',
            notificationFunction: Notification.error,
        },
        {
            querySelector: '.warning',
            notificationFunction: Notification.warning,
        },
        {
            querySelector: '.info',
            notificationFunction: Notification.info,
        },
        {
            querySelector: '.message',
            notificationFunction: Notification.info,
        },
    ]
    notificationTypes.forEach((notificationType) => {
        const element = document.querySelector(
            '.notifications ' + notificationType.querySelector
        )
        if (element)
            notificationType.notificationFunction(
                element.dataset['title']
                    ? element.dataset['title'].trim()
                    : null,
                element.innerHTML.trim()
            )
    })
})
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="e192674e-32bb-5f64-b9d7-131d6e80b156")}catch(e){}}();
//# debugId=e192674e-32bb-5f64-b9d7-131d6e80b156
